import React from 'react'
import { PageProps, graphql } from 'gatsby'

import Layout from 'components/layout/Layout'
import SEO from 'components/layout/SEO'
import Breadcrumbs from 'components/layout/Breadcrumbs'

import Header from 'components/layout/Headers/Secondary'
import HeaderFilters from 'components/layout/Headers/Secondary/Filters'
import ContactIntro from 'components/layout/Sections/Contact/Intro'
import FormSection from 'components/layout/Sections/Contact/FormSection'

import { CONTACT_LINKS } from 'constants/links'

import type { ContactCustomerServicePageQuery } from 'types/graphql'

const ContactCustomerService: React.FC<
  PageProps<ContactCustomerServicePageQuery>
> = ({ data }) => {
  const { page } = data

  const PAGE = page?.pageCustomerService
  const PAGE_SEO = page?.seo

  if (!PAGE || !PAGE_SEO) throw new Error("CMS data didn't load properly")

  return (
    <Layout>
      <SEO seo={PAGE_SEO} />
      <Breadcrumbs crumbs={[{ name: 'Kontakt' }]} />
      <Header
        title={PAGE.customerServicePageS1Title!}
        image={{
          src: PAGE.customerServicePageS1Img?.localFile?.childImageSharp
            ?.gatsbyImageData!,
          alt: PAGE.customerServicePageS1Img?.altText!,
        }}
      />
      <main>
        <HeaderFilters options={CONTACT_LINKS} />
        <ContactIntro
          title={PAGE.customerServicePageS2Title!}
          description={PAGE.customerServicePageS2Description!}
          icon={PAGE.customerServicePageS2Img?.localFile?.publicURL!}
        />
        <FormSection
          title={PAGE.customerServicePageS3Title!}
          formID="customer-service-form"
        />
      </main>
    </Layout>
  )
}

export default ContactCustomerService

export const query = graphql`
  query ContactCustomerServicePage($locale: String) {
    page: wpPage(
      language: { slug: { eq: $locale } }
      slug: { regex: "/obsluga-klienta/" }
    ) {
      seo {
        ...WpSEO
      }
      pageCustomerService {
        customerServicePageS1Title
        customerServicePageS1Img {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1100
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        customerServicePageS2Title
        customerServicePageS2Description
        customerServicePageS2Img {
          localFile {
            publicURL
          }
        }
        customerServicePageS3Title
      }
    }
  }
`
